import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import faCheckSquare from "@fortawesome/fontawesome-free-solid/faCheckSquare";
import Layout from "../components/Layout";
import ProductImages from "../components/ProductImages";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  padding: 1rem;

  .content-1-section {
    width: 100%;
    padding: 1rem;
    border: 1px solid white;
    transition: all 0.3s ease;
  }

  @media screen and (min-width: 900px) {
    width: 800px;
    margin: 0 auto;
    margin-top: 2rem;
    padding: 0;
  }
`;

const InnerDiv = styled.div`
  border-radius: 5px;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  p {
    font-size: 1.2em;
    line-height: 1.6;
  }

  @media (min-width: 1000px) {
    padding-bottom: 100px;
  }
`;

const ProductDiv = styled.div`
  background: white;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 4px 8px rgba(66, 180, 228, 0.1);
    transform: translateY(-2px);
  }

  .product-div-inner {
    margin: 9px;
    border-radius: 5px;
    padding: 9px;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

const ProductHeader = styled.div`
  display: flex;
  margin-bottom: 5px;
  align-items: center;
  width: 100%;
  padding: 0.5rem 0;

  h3,
  h2 {
    color: #42b4e4;
    margin: 0;
    margin-left: 5px;
    font-weight: 600;
  }

  div {
    margin-left: 10px;
    margin-bottom: 15px;
  }
`;

const ProductContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3px;

  p {
    font-size: 1.1rem;
    line-height: 1.5;
    padding: 0.5rem 0;
  }
`;

const TitleContent = styled.div`
  border-radius: 5px;
  color: #42b4e4;
  background: white;
  width: 100%;
  padding: 1.5rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 4px 12px rgba(66, 180, 228, 0.1);
  }

  .black-text {
    color: rgba(0, 0, 0, 0.75);
  }

  .inner {
    margin: 0 auto;
    padding: 0 1.5rem;

    p {
      line-height: 1.6;
      margin: 1rem 0;
    }

    strong {
      color: #42b4e4;
    }
  }

  .quality-system {
    margin-left: 1.5rem;

    p {
      margin: 0.75rem 0;
      line-height: 1.5;
    }
  }

  .seafood-types {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .types-content {
      display: flex;
      flex-direction: column;
      gap: 20px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 1.5rem;
      border-radius: 8px;
      transition: all 0.3s ease;
      background: linear-gradient(to right, #ffffff, #f8f9ff);

      &:hover {
        box-shadow: 0 4px 12px rgba(66, 180, 228, 0.1);
        transform: translateY(-2px);
      }

      .title {
        font-weight: bold;
        font-size: 18px;
        color: #42b4e4;
        margin-bottom: 0.5rem;
      }

      .subtext {
        font-size: 16px;
        margin-top: 8px;
        font-style: italic;
        color: rgba(0, 0, 0, 0.6);
      }

      .image-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        gap: 1rem;
        margin-top: 1.5rem;

        .image-container {
          aspect-ratio: 1;
          border-radius: 8px;
          overflow: hidden;
          border: 1px solid rgba(0, 0, 0, 0.1);
          transition: all 0.3s ease;

          &:hover {
            transform: scale(1.05);
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 0.3s ease;
          }
        }
      }
    }

    p {
      margin: 0;
    }

    .content-items {
      color: rgba(0, 0, 0, 0.75);
      display: flex;
      flex-direction: column;
      gap: 1.2rem;

      .item-title {
        font-weight: bold;
        min-width: 120px;
        color: #42b4e4;
      }

      .item-content {
        display: grid;
        grid-template-columns: 1fr 2fr;
        align-items: flex-start;
        padding: 0.5rem;
        border-radius: 4px;
        transition: all 0.2s ease;

        &:hover {
          background: rgba(66, 180, 228, 0.03);
        }

        p {
          font-size: 16px;
          line-height: 1.6;
        }
      }
    }

    .and-more {
      font-weight: bold;
      margin-left: 1.5rem;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      color: #42b4e4;
      transition: all 0.2s ease;

      &:hover {
        transform: translateX(5px);
      }
    }
  }

  p {
    font-size: 18px;
  }

  h1 {
    margin-bottom: 3rem;
    font-weight: 600;
    color: #42b4e4;
  }

  h2 {
    text-align: center;
    margin: 1.5rem 0;
    font-weight: 600;
    color: #42b4e4;
  }

  @media screen and (min-width: 900px) {
    .seafood-types {
      gap: 25px;

      .types-content {
        gap: 30px;

        .title {
          font-size: 20px;
        }

        .subtext {
          font-size: 18px;
        }
      }
    }

    .image-grid {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }

    .item-content {
      display: grid;
      grid-template-columns: 1fr 3fr;
      gap: 1.5rem;

      p {
        font-size: 16px !important;
      }
    }
  }
`;

export default ({ data }) => {
  const {
    squid1,
    squid2,
    squid3,
    squid4,
    squid5,
    lobster1,
    lobster2,
    lobster3,
    lobster4,
    lobster5,
    lobster6,
    whelk1,
    whelk2,
    whelk3,
  } = data;

  const seafoodContent = [
    {
      title: "Atlantic Snow Crab : Chinoecetes Opilio",
      items: [
        {
          title: "Fishing Area:",
          subtitle: "NAFO Area 21",
        },
        {
          title: "Season:",
          subtitle: "April to July",
        },
        {
          title: "Size grading:",
          subtitle: "with Section",
        },
        {
          title: "Packing:",
          subtitle: "Brine frozen (International standard)",
        },
      ],
    },
    {
      title: "Frozen Lobster (W/R) : Homarus Americanus",
      subtext: "Frozen Lobster Tails (Raw)",
      items: [
        {
          title: "Origin:",
          subtitle: "Canada",
        },
        {
          title: "Season:",
          subtitle: "May to September",
        },
        {
          title: "Packing:",
          subtitle: "Frozen raw tails, 1 x 10 lb",
        },
        {
          title: "Size:",
          subtitle: "3-4 oz, 4 oz, 4-5 oz, 5-6 oz",
        },
      ],
      images: [lobster1, lobster2, lobster3, lobster4, lobster5, lobster6],
    },
    {
      title: "Cooked in Shell Whelk : Buccinum Undatum",
      items: [
        {
          title: "Origin:",
          subtitle: "Canada",
        },
        {
          title: "Season:",
          subtitle: "June to October",
        },
        {
          title: "Size:",
          subtitle: "30g – 50g, 50g – 70g",
        },
        {
          title: "Packing:",
          subtitle: "IQF, 1 x 10 kg box",
        },
      ],
      images: [whelk1, whelk2, whelk3],
    },
    {
      title: "Frozen Squid (W/R) : Illex",
      items: [
        {
          title: "Origin:",
          subtitle: "Canada, NAFO area 21",
        },
        {
          title: "Season:",
          subtitle: "August – October",
        },
        {
          title: "Packing:",
          subtitle: "Quick frozen, Hand – laid, 1 x 10 kg box",
        },
        {
          title: "Size:",
          subtitle: "150g – 250g, 200g – 300g, 300g – 400g and 400g +",
        },
      ],
      images: [squid2, squid3, squid4, squid5, squid1],
    },
    // {
    //   title: "Lobster : Homarus Americanus",
    //   items: [
    //     {
    //       title: "Fishing Area:",
    //       subtitle: "NAFO area 21",
    //     },
    //     {
    //       title: "Season:",
    //       subtitle: "Year round (subject seasonal quota with zoning)",
    //     },
    //     {
    //       title: "Size grading:",
    //       subtitle: "all size",
    //     },
    //     {
    //       title: "Packing:",
    //       subtitle: "Live and Frozen (International standard)",
    //     },
    //   ],
    // },
    {
      title: "Mackerel : Scomber Scombrus",
      items: [
        {
          title: "Fishing Area:",
          subtitle: "NAFO Area 21",
        },
        {
          title: "Season:",
          subtitle: "August to November",
        },
        {
          title: "Packing:",
          subtitle: "Quick frozen at blast",
        },
      ],
    },
    // {
    //   title: "Squid : Illex",
    //   items: [
    //     {
    //       title: "Fishing Area:",
    //       subtitle: "NAFO Area 21 (By jigging near inshore)",
    //     },
    //     {
    //       title: "Season:",
    //       subtitle: "August to November",
    //     },
    //     {
    //       title: "Packing:",
    //       subtitle: "Quick frozen at blast",
    //     },
    //   ],
    // },
    {
      title: "Capelin : Mallotus Villosus",
      subtext: "Female Capelin is an Asian delicacy (Roe), called Shishamo",
      items: [
        {
          title: "Fishing:",
          subtitle: "NAFO Area 21",
        },
        {
          title: "Season:",
          subtitle: "June to August",
        },
        {
          title: "Packing:",
          subtitle: "International Standard",
        },
      ],
    },
  ];

  return (
    <Layout>
      <Container>
        <TitleContent>
          {/* <h1>Canadian Seafood: All fishing area and quality system</h1> */}
          <h2>Fishing area: NAFO Area 21</h2>
          <h2>Season: April to November</h2>
          {/* <h2>Wild caught, clean and cold North Atlantic ocean.</h2>
          <h2>Quality System: HACCP, CFIA (Canadian Food Inspection Agency)</h2> */}
        </TitleContent>
        <TitleContent>
          <h2>Seafood and Processing</h2>
          <div className="inner">
            <p>
              <strong>Processing Features:</strong> Sustainable, Wild, Natural
              Seafood Captured in the Clean cold Atlantic Ocean and quick single
              frozen
            </p>
            <p>
              <strong>Packing:</strong> International Standard
            </p>
            <p>
              <strong>Quality System:</strong>
            </p>
            <p className="quality-system">
              <p>
                <strong>HACCP</strong> - Hazard Analysis and Critical Control
                Point
              </p>
              <p>
                <strong>CFIA</strong> - Canadian Food Inspection Agency
              </p>
            </p>
          </div>
        </TitleContent>

        <TitleContent>
          <h2>Seafood Types</h2>

          <div className="seafood-types">
            {seafoodContent.map((content, i) => (
              <div key={i} className="types-content">
                <div className="content-title">
                  <p className="title">{content.title}</p>
                  {content.subtext && (
                    <p className="subtext">{content.subtext}</p>
                  )}
                </div>
                <div className="content-items">
                  {content.items?.map((item, y) => (
                    <div key={y} className="item-content">
                      <p className="item-title">{item.title}</p>
                      <p>
                        {item.subtitle.split(",").map((text, index) => (
                          <React.Fragment key={index}>
                            {index > 0 && <br />}
                            {text.trim()}
                          </React.Fragment>
                        ))}
                      </p>
                    </div>
                  ))}
                </div>
                {content.images && <ProductImages imgs={content.images} />}
              </div>
            ))}

            <div className="and-more">
              <p>And more...</p>
            </div>
          </div>
        </TitleContent>

        <InnerDiv></InnerDiv>
      </Container>
    </Layout>
  );
};

{
  /* <TitleContent>
          <h2>Conclusion</h2>
          <p className="black-text">
            I am hoping to export pollution-free, clean, cold, Ocean Canadian
            Seafood to Korean customers now and for the next generations.
          </p>
          <p className="black-text">
            By the way, I am glas and appreciative of HYCU for giving older
            people a chance of studying. It has really helped me reflect upon my
            life and provided more understanding of Canadian people and society.
          </p>
        </TitleContent> */
}
{
  /* <ProductDiv>
            <div className="product-div-inner">
              <ProductHeader>
                <FontAwesomeIcon
                  icon={faCheckSquare}
                  size="4x"
                  color="#42b4e4"
                />
                <div>
                  <h2>Atlantic Snow Crab</h2>
                  <h3>Chinoecetes Opilio</h3>
                </div>
              </ProductHeader>
              <ProductContent>
                <p>Production Season:</p>
                <p>April - July</p>
                <p>Products:</p>
                <div>
                  <p>Raw section and leg</p>
                  <p>Whole cooked</p>
                  <p>Cooked section (cluster)</p>
                </div>
                <p>Packing:</p>
                <div>
                  <p>Quick single frozen - brine and blast</p>
                  <p>30 LBS Box with poly-liner</p>
                </div>
                <p>Size:</p>
                <div>
                  <p>5 - 8 oz</p>
                  <p>8 - 10 oz</p>
                  <p>10 - 12 oz</p>
                </div>
              </ProductContent>
              <ProductImages
                imgs={[crab1, crab2, crab3, crab4, crab5, crab6, crab7]}
              />
            </div>
          </ProductDiv> */
}
{
  /* <ProductDiv>
            <ProductHeader>
              <FontAwesomeIcon icon={faCheckSquare} size="4x" color="#42b4e4" />
              <div>
                <h2>Lobster</h2>
                <h3>Homarus Americanus</h3>
              </div>
            </ProductHeader>
            <ProductContent>
              <p>Live:</p>
              <p>Availability Year Round - By Each Fishing Area</p>
              <p>Frozen:</p>
              <p>April - August</p>
              <p>Packing:</p>
              <div>
                <p>10 LBS Box, Individual Net</p>
                <p>Cooked, Brine frozen</p>
              </div>
              <p>Size:</p>
              <div>
                <p>250 g - 300 g (approximately 5%)</p>
                <p>300 g - 350 g (approximately 40%)</p>
                <p>350 g - 400 g (approximately 40%)</p>
                <p>400 g - 450 g (approximately 15%)</p>
                <p>450 g - 500 g</p>
                <p>500 g - 550 g</p>
                <p>550 g - 600 g</p>
              </div>
            </ProductContent>
            <ProductImages
              imgs={[
                lobster1,
                lobster2,
                lobster3,
                lobster4,
                lobster5,
                lobster6,
              ]}
            />
          </ProductDiv>
          <ProductDiv>
            <ProductHeader>
              <FontAwesomeIcon icon={faCheckSquare} size="4x" color="#42b4e4" />
              <div>
                <h2>Capelin</h2>
                <h3>Mallotus Villosus (Shishamo)</h3>
              </div>
            </ProductHeader>
            <ProductContent>
              <p>Production Season:</p>
              <p>June - August</p>
              <p>Products:</p>
              <div>
                <p>Whole round</p>
                <p>Female and male capelin</p>
              </div>
              <p>Size Grading:</p>
              <div>
                <p>41 - 45 pcs/kg</p>
                <p>46 - 50 pcs/kg</p>
                <p>51 - 55 pcs/kg</p>
                <p>56 - 60 pcs/kg</p>
                <p>61 - 65 pcs/kg</p>
              </div>
              <p>Packing:</p>
              <div>
                <p>Air Blast Frozen in Ploy - Liner</p>
                <p>4.5kg x 2, 10kg, 15kg</p>
              </div>
            </ProductContent>
            <ProductImages imgs={[capelin1, capelin2, capelin3, capelin4]} />
          </ProductDiv>
          <ProductDiv>
            <ProductHeader>
              <FontAwesomeIcon icon={faCheckSquare} size="4x" color="#42b4e4" />
              <div>
                <h2>Squid</h2>
                <h3>Illex</h3>
              </div>
            </ProductHeader>
            <ProductContent>
              <p>Production Season:</p>
              <p>August - November</p>
              <p>Size:</p>
              <div>
                <p>200 g - 400 g</p>
                <p>300 g - 500 g</p>
                <p>400 g - 600 g</p>
                <p>600 g</p>
              </div>
              <p>Packing:</p>
              <div>
                <p>Hand-laid,</p>
                <p>10 kg box</p>
              </div>
            </ProductContent>
            <ProductImages
              imgs={[squid1, squid2, squid3, squid4, squid5, squid6]}
            />
          </ProductDiv>
          <ProductDiv>
            <ProductHeader>
              <FontAwesomeIcon icon={faCheckSquare} size="4x" color="#42b4e4" />
              <div>
                <h2>Whelk</h2>
                <h3>Buccinum Undatum</h3>
              </div>
            </ProductHeader>
            <ProductContent>
              <p>Fishing Area:</p>
              <p>Gulf Zone 3, 4, 5</p>
              <p>Season:</p>
              <p>May - October</p>
              <p>Size:</p>
              <div>
                <p>{`SS: < 9 g`}</p>
                <p>S: 10 g - 17 g</p>
                <p>M: 18 g - 24 g</p>
                <p>L: 25 g +</p>
              </div>
              <p>Packing:</p>
              <div>
                <p>Boiled frozen meat</p>
                <p>2 kg block box X 10 = 20 kg box</p>
              </div>
            </ProductContent>
            <ProductImages imgs={[whelk1, whelk2, whelk3, whelk4]} />
          </ProductDiv>
          <ProductDiv>
            <ProductHeader>
              <FontAwesomeIcon icon={faCheckSquare} size="4x" color="#42b4e4" />
              <div>
                <h2>Red Fish (Ocean Perch)</h2>
                <h3>Sobastes Marinus</h3>
              </div>
            </ProductHeader>
            <ProductContent>
              <p>Production Season:</p>
              <p>Year Round</p>
              <p>Size:</p>
              <div>
                <p>W/R, 200 g - 400 g, 200 g - 300 g</p>
                <p>H&G, 80 g - 100 g, 100 g - 150 g</p>
              </div>
              <p>Packing:</p>
              <div>
                <p>Hand-laid, Anti-oxidant treated</p>
                <p>10 kg box,</p>
              </div>
            </ProductContent>
            <ProductImages
              imgs={[redfish1, redfish2, redfish3, redfish4, redfish5]}
            />
          </ProductDiv>
          <ProductDiv>
            <ProductHeader>
              <FontAwesomeIcon icon={faCheckSquare} size="4x" color="#42b4e4" />
              <div>
                <h2>Atlantic Mackerel</h2>
                <h3>Scomber Scombrus</h3>
              </div>
            </ProductHeader>
            <ProductContent>
              <p>Production Season:</p>
              <p>September - November</p>
              <p>Size:</p>
              <div>
                <p>200 g - 400 g</p>
                <p>300 g - 500 g</p>
                <p>400 g - 600 g</p>
                <p>600 g +</p>
              </div>
              <p>Packing:</p>
              <div>
                <p>Hand-laid</p>
                <p>9 kg x 2 = 18 kg box and 15 kg</p>
              </div>
              <p>Fat Content:</p>
              <p>Approximately 22% - 24%</p>
            </ProductContent>
          </ProductDiv>
          <ProductDiv>
            <ProductHeader>
              <FontAwesomeIcon icon={faCheckSquare} size="4x" color="#42b4e4" />
              <div>
                <h2>Atlantic Cod</h2>
                <h3>Gadus Morhua</h3>
              </div>
            </ProductHeader>
            <ProductContent>
              <p>Production Season:</p>
              <p>August - November</p>
              <p>Production:</p>
              <div>
                <p>Filter Split:</p>
                <p>0.75 kg - 1.75 kg (and 1 kg - 2 kg)</p>
                <p>Head with Collar</p>
                <p>Roe and Milt</p>
              </div>
              <p>Packing:</p>
              <div>
                <p>Fresh frozen</p>
              </div>
            </ProductContent>
          </ProductDiv>
          <ProductDiv>
            <ProductHeader>
              <FontAwesomeIcon icon={faCheckSquare} size="4x" color="#42b4e4" />
              <div>
                <h2>Grey Sole (Witch Flounder)</h2>
                <h3>Plie Grise</h3>
              </div>
            </ProductHeader>
            <ProductContent>
              <p>Size:</p>
              <div>
                <p>200 g - 400 g</p>
                <p>400 g - 600 g</p>
                <p>600 g +</p>
              </div>
              <p>Packing:</p>
              <div>
                <p>Frozen W/R, Shatterpack</p>
                <p>15 kg box</p>
              </div>
            </ProductContent>
            <ProductImages imgs={[sole1, sole2, sole3, sole4]} />
          </ProductDiv>
          <ProductDiv>
            <ProductHeader>
              <FontAwesomeIcon icon={faCheckSquare} size="4x" color="#42b4e4" />
              <div>
                <h2>Atlantic Herring</h2>
                <h3>Clupea Harengus</h3>
              </div>
            </ProductHeader>
            <ProductContent>
              <p>Production Season:</p>
              <p>November - February (Winter season)</p>
              <p>Fat content:</p>
              <p>Approxmiately 20% - 22%</p>
              <p>W/R:</p>
              <div>
                <p>200 g - 300 g</p>
                <p>300 g - 500 g</p>
              </div>
              <p>Fillet:</p>
              <p>50 - 80 / kg</p>
              <p>Flaps:</p>
              <p />
              <p>Packing:</p>
              <p>10 kg box and 15 kg box</p>
            </ProductContent>
            <ProductImages imgs={[herring1, herring2, herring3]} />
          </ProductDiv>
          <ProductDiv>
            <ProductHeader>
              <FontAwesomeIcon icon={faCheckSquare} size="4x" color="#42b4e4" />
              <div>
                <h2>Other Species</h2>
              </div>
            </ProductHeader>
            <ProductContent>
              <p>Atlantic Herring</p>
              <p />
              <p>Monk Fish</p>
              <p />
              <p>Skate</p>
              <p />
              <p>Whelk meat</p>
              <p />
              <p>Razor Clam meat</p>
              <p />
            </ProductContent>
          </ProductDiv> */
}

export const pageQuery = graphql`
  query IndexQuery {
    # Lobster images
    lobster1: file(relativePath: { eq: "lobster/IMG_1533.JPG" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lobster2: file(relativePath: { eq: "lobster/IMG_1534.JPG" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lobster3: file(relativePath: { eq: "lobster/IMG_1784.JPG" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lobster4: file(relativePath: { eq: "lobster/IMG_1785.JPG" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lobster5: file(relativePath: { eq: "lobster/IMG_1786.JPG" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lobster6: file(relativePath: { eq: "lobster/IMG_1788.JPG" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    # Squid images
    squid1: file(relativePath: { eq: "squid/IMG_0892.JPG" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    squid2: file(relativePath: { eq: "squid/IMG_0897.JPG" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    squid3: file(relativePath: { eq: "squid/IMG_0920.JPG" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    squid4: file(relativePath: { eq: "squid/IMG_1456.JPG" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    squid5: file(relativePath: { eq: "squid/IMG_1459.JPG" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    # Whelk images
    whelk1: file(
      relativePath: { eq: "whelk/Cooked in Shell (Finished Product).jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    whelk2: file(relativePath: { eq: "whelk/Whelk 1.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    whelk3: file(relativePath: { eq: "whelk/Whelk 2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
