import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";

import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import faArrowRight from "@fortawesome/fontawesome-free-solid/faArrowRight";
import faArrowLeft from "@fortawesome/fontawesome-free-solid/faArrowLeft";
const Wrapper = styled.div`
  width: 800px;
  max-width: 100%;

  .gatsby-image-wrapper {
    transition: opacity 0.2s ease;
    opacity: ${(props) => (props.isChanging ? 0.8 : 1)};
  }

  .arrows {
    margin-top: 5px;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      cursor: pointer;
      transition: transform 0.2s ease;

      &:hover {
        transform: scale(1.05);
      }

      &:active {
        transform: scale(0.95);
      }
    }

    p {
      font-size: 1.5rem;
      color: #42b4e4;
    }
  }

  @media (max-width: 1000px) {
    width: unset;
  }
`;

class ProductImages extends React.Component {
  state = {
    numImgs: this.props.imgs.length || 0,
    selected: 1,
    isChanging: false,
  };

  handleArrow = (direction) => {
    this.setState({ isChanging: true });

    this.setState(
      {
        selected:
          direction === "left"
            ? this.state.selected === 1
              ? this.state.numImgs
              : this.state.selected - 1
            : this.state.selected === this.state.numImgs
            ? 1
            : this.state.selected + 1,
      },
      () => {
        setTimeout(() => {
          this.setState({ isChanging: false });
        }, 200);
      }
    );
  };

  render() {
    const { numImgs, selected, isChanging } = this.state;
    const { imgs } = this.props;
    return (
      <Wrapper isChanging={isChanging}>
        <Img fluid={imgs[selected - 1].childImageSharp.fluid} />
        <div className="arrows">
          <span onClick={() => this.handleArrow("left")}>
            <FontAwesomeIcon icon={faArrowLeft} size="4x" color="#42b4e4" />
          </span>
          <p>{`${selected}/${numImgs}`}</p>
          <span onClick={() => this.handleArrow("right")}>
            <FontAwesomeIcon icon={faArrowRight} size="4x" color="#42b4e4" />
          </span>
        </div>
      </Wrapper>
    );
  }
}
export default ProductImages;
